exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-podsumowanie-js": () => import("./../../../src/pages/kontakt/podsumowanie.js" /* webpackChunkName: "component---src-pages-kontakt-podsumowanie-js" */),
  "component---src-pages-praca-podsumowanie-js": () => import("./../../../src/pages/praca/podsumowanie.js" /* webpackChunkName: "component---src-pages-praca-podsumowanie-js" */),
  "component---src-pages-szkolenia-podsumowanie-js": () => import("./../../../src/pages/szkolenia/podsumowanie.js" /* webpackChunkName: "component---src-pages-szkolenia-podsumowanie-js" */),
  "component---src-templates-dynamic-blog-post-js": () => import("./../../../src/templates/dynamicBlogPost.js" /* webpackChunkName: "component---src-templates-dynamic-blog-post-js" */),
  "component---src-templates-dynamic-jobs-js": () => import("./../../../src/templates/dynamicJobs.js" /* webpackChunkName: "component---src-templates-dynamic-jobs-js" */),
  "component---src-templates-dynamic-page-js": () => import("./../../../src/templates/dynamicPage.js" /* webpackChunkName: "component---src-templates-dynamic-page-js" */),
  "component---src-templates-dynamic-trainings-js": () => import("./../../../src/templates/dynamicTrainings.js" /* webpackChunkName: "component---src-templates-dynamic-trainings-js" */)
}

