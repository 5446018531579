import React from "react"
import { Script } from "gatsby"

export const wrapPageElement = ({ element }) => {
  return (
    <>
      {element}
       <Script
        defer
        src={`https://www.googletagmanager.com/gtag/js?id=G-7QRKSX3N4J`}
        strategy="idle"
        // forward={[`dataLayer.push`]}
      />
      <Script defer id="gtm-init" strategy="idle">
        {`
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('config', 'G-7QRKSX3N4J');
      `}
      </Script>
      {/* <Script defer id="hotjar-init" strategy="idle">
        {`
        (function(h,o,t,j,a,r){   
          h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
          h._hjSettings={hjid:2768654,hjsv:6};
          a=o.getElementsByTagName('head')[0];
          r=o.createElement('script');r.async=1;
          r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
          a.appendChild(r); 
        })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
      `}
      </Script>  */}
    </>
  )
}